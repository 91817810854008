import { executeJsonGetMethod, executePostFormDataMethod } from '../api/rest/restApiUtils'

/**
 * Подать заявление на преподавателя
 * @method submitApplication
 * @return {Response} Fetch Response
 */
export function submitApplication(data: any, file: any) {
    const myHeaders = new Headers()
    myHeaders.append('Accept', '*/*')
    const formdata = new FormData()
    formdata.append('userTeacherDto', JSON.stringify(data))
    formdata.append('file', file, 'default_avatar.jpg')

    return executePostFormDataMethod('/teachers/application', formdata)
}


/**
 * Подать загрузить информацию по преподавателю
 * @method getTeacherInfo
 * @return {Response} Fetch Response
 */
export function getTeacherInfo(id: number) {
    return executeJsonGetMethod(`/teachers/${id}`)
}

