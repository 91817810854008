import { useEffect, useState } from 'react'
import * as API_TEACHERS from '../../../../../shared/http/teacher'

const Teachers = () => {

    const [data, setData] = useState()
    const [url, setUrl] = useState()

    /**
     * Загрузить пользователей с их привилегиями
     */
    const loadTeacherDocuments = () => {
        API_TEACHERS.getTeacherInfo(257).then((data) => {
            const data2 = data.data
            setUrl(data.data.file)
            data2.file = ''
            setData(data2)

        })
    }

    useEffect(() => {
        loadTeacherDocuments()
    }, [])

    return <>
        Преподы {data && JSON.stringify(data)}
        {url && data && <a
            href={`data:${data.fileType};base64,` + url}
            target='_blank'
            download={data.fileName}
        >Download file</a>}
    </>
}

export default Teachers
